<template>
  <div class="layout-a" v-if="offer.length">
    <div class="main-area">
      <Scrollable>
        <div class="scroll-content"
          :class="{
            'full-web': !isIntegration,
            'standalone': isStandalone,
            'integration': isIntegration,
            'mobile-integration': isIntegration
            && isMob
            && config.tenantId !== '1042c99a-d579-4bee-8c37-03cff3dd6af4'}">
          <div class="betting">
             <div class="header">
               <Header
                 @ShowInfo="toggleModal"
                 @ShowSettings="toggleSettings">
               </Header>
            </div>
            <div class="visualization"
              :class="{'no-user-select': !isStagingEnv}">
              <Visualization />
              <!-- <VisualizationHeader v-if="!isVgr"
                class="visualization-header"
                :icon="productIcon"/>
              <VisualizationFooter v-if="!isVgr"
                :label="footerLabel"
                class="visualization-footer"/> -->
            </div>
            <div class="playground">
              <BettingArea />
              <!-- <div class="mobile-betslip">
                <BetslipArea />
              </div> -->
            </div>
          </div>
          <div class="events-area">
            <EventsArea />
          </div>
        </div>
        <Modal :fullScreen="isMob"
            :showFooter="true"
            class="events-container"
            width="800px"
            height="100%"
            v-if="showModal"
            @closeModal="toggleModal">
          <template #header>
            <div class="info">
              <div class="product-info">{{ productName }}</div>
              <div class="round-info"><span>{{ roundLabel }} </span>{{ currentRound }}</div>
              <i class="icon icon-close-a"
                @click="toggleModal" />
            </div>
          </template>
          <template #body>
            <EventsArea />
          </template>
        </Modal>
        <Modal :fullScreen="isMob"
            :showFooter="true"
            class="settings-container"
            width="344px"
            height="100%"
            v-if="showSettings"
            @closeModal="toggleSettings">
          <template #header>
            <div class="settings-header">
              <div class="header-title">{{ settingsLabel }}</div>
            </div>
          </template>
          <template #body>
            <div class="options-wrapper">
              <Checkbox label="In game audio" v-model="settings.inGameAudio"/>
              <Checkbox label="High quality" v-model="settings.highQuality"/>
              <Checkbox label="Show tips" v-model="settings.showTips"/>
            </div>
          </template>
          <template #footer>
            <div class="footer-button" @click="toggleSettings">
              {{ cancelLabel }}
            </div>
            <div class="footer-button" @click="applySettings">
              {{ acceptLabel }}
            </div>
          </template>
        </Modal>
      </Scrollable>
    </div>
    <div class="ticket-area">
      <BetslipArea />
    </div>
    <FullScreen v-if="isMob && isUserLoggedIn && !isTablet"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  Scrollable,
  // GamesHeader,
  Modal,
  Checkbox,
  FullScreen,
} from '@nsftx/games-sdk-js';
import isMobile from 'ismobilejs';
import { startCase } from 'lodash';
import Visualization from '@/components/Visualization';
import BettingArea from '@/components/BettingArea';
import EventsArea from '@/components/EventsArea';
import BetslipArea from '@/components/BetslipArea';
//  import VisualizationFooter from '@/components/VisualizationFooter';
//  import VisualizationHeader from '@/components/VisualizationHeader';
import Header from '@/components/Header';

export default {
  name: 'A',
  components: {
    Visualization,
    BettingArea,
    EventsArea,
    BetslipArea,
    Scrollable,
    // GamesHeader,
    //  VisualizationFooter,
    //  VisualizationHeader,
    Modal,
    Checkbox,
    Header,
    FullScreen,
  },
  data() {
    return {
      showModal: false,
      showSettings: false,
      showBalance: true,
      settings: {
        inGameAudio: false,
        highQuality: false,
        showTips: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'offer',
      'user/balance',
      'translations',
      'config',
      'currentRound',
    ]),
    ...mapGetters({
      isBetslipOpened: 'betslip/isBetslipOpened',
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    isIntegration() {
      return this.config.ui.config.mode && this.config.ui.config.mode === 'integration';
    },
    isStandalone() {
      return this.config.ui.config.mode && this.config.ui.config.mode === 'standalone';
    },
    isStagingEnv() {
      return this.config.environment === 'staging';
    },
    footerLabel() {
      return this.translations.games_stream_delay_warning;
    },
    roundLabel() {
      return this.translations.general_round;
    },
    settingsLabel() {
      return this.translations.general_settings;
    },
    cancelLabel() {
      return this.translations.general_cancel;
    },
    acceptLabel() {
      return this.translations.general_accept;
    },
    isTablet() {
      return isMobile().tablet;
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    productName() {
      return startCase(this.config.productName);
    },
    productIcon() {
      const products = {
        VirtualGreyhoundRaces: 'icon icon-greyhound-races-v',
        GreyhoundRaces: 'icon icon-greyhound-races-v',
        VirtualMotorcycleSpeedway: 'icon icon-virtual-motorcycle-speedway',
        VirtualHorseRaces: 'icon icon-horse-races-v',
        SlotCarRaces: 'icon icon-slot-car-races',
      };
      return products[this.config.productName];
    },
    isVgr() {
      return this.config.productAlias === 'VirtualGreyhoundRaces';
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    applySettings() {
      this.$emit('applySettings', this.settings);
      this.toggleSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
  .layout-a {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;

    .main-area {
      .scroll-content {
        background-color: var(--background-1);
        &.full-web,
        &.mobile-integration,
        &.standalone {
          max-height: 100vh;
        }
        //&.mobile-integration {
        //  max-height: 100vh;
        //}
      }
      .betting {
        .visualization-wrapper {
          z-index: 0;
        }
        .visualization {
          position: relative;
          float: left;
          width: 100%;
          height: 100%;
          overflow: hidden;
          &.no-user-select {
            pointer-events: none;
          }
          .visualization-footer {
            position: absolute;
            bottom: 0;
          }
          .visualization-header {
            position: absolute;
            top: 0;
          }
        }
        .playground {
          float: left;
          width: 100%;
        }
      }

      .events-area {
        display: flex;
        background-color: var(--card);
      }
    }
    ::v-deep .modal-back.events-container {
      .modal {
        background-color: var(--card);
        padding: 0;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          padding: 0px !important;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 24px;
            }
          }
        }
      }
    }
    ::v-deep .modal-back.settings-container {
      .modal {
        background-color: var(--event-details-bg);
        padding: 0;
        .modal-header {
          background-color: var(--event-details-bg);
          height: 60px;
          .settings-header {
            padding: 24px;
            font-size: 18px;
            color: var(--text-primary-1);
            font-weight: bold;
          }
        }
        .modal-body {
          .options-wrapper {
            display: flex;
            flex-direction: column;
            padding: 24px;
          }
          .checkbox {
            margin-bottom: 20px;
            color: var(--text-primary-1);
            input + label:after {
              border-color: var(--event-details-bg);
            }
          }
        }
        .modal-footer {
          padding: 16px;
          .footer-button {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media (min-width: 306px) and (max-width: 659px) {
    ::v-deep {
      .tabs {
        .tab-button {
          max-width: unset;
        }
      }
    }
    .layout-a {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      padding-top: 0;
      .scrollable {
        width: inherit;
      }
      .main-area {
        width: 100%;
        float: left;
        .betting {
          float: left;
          width: 100%;
          .betting-area {
            padding-bottom: 100px;
          }
          .playground {
            float: left;
            width: 100%;
            position: relative;

            .mobile-betslip {
              float: left;
              width: 100%;
              z-index: 0;
              position: relative;

              &.expanded {
                position: unset;
              }
            }
          }
        }
        .events-area {
          display: none;
          float: left;
          width: 100%;
          padding-bottom: 100px;
        }
      }
      .ticket-area {
        display: block;
        width: 100%;
        float: left;
        position: fixed;
        bottom: 0;
      }
    }
  }

  @media (min-width: 660px) and (max-width: 959px) {
    .main-area {
      max-width: 800px;
      width: 100vw;
      margin: 0 auto;
      padding-bottom: 100px;

      .betting {
        .playground {
          .mobile-betslip {
            display: none;
          }
        }
      }

      .events-area {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        padding-bottom: 100px;
      }
    }
    .ticket-area {
      width: 660px;
      position: fixed;
      bottom: 0;
    }
  }

  @media (min-width: 960px) and (max-width: 1099px) {
    .main-area {
      width: 800px;
      margin: 0 auto;
      padding-bottom: 100px;

      .betting,
      .events-area {
        width: 100%;
        display: inline-block;
      }

      .betting {
        .playground {
          .mobile-betslip {
            display: none;
          }
        }
      }

      .events-area {
        padding-bottom: 100px;
      }
    }
    .ticket-area {
      width: 660px;
      position: fixed;
      bottom: 0;
    }
  }

  @media (min-width: 1100px) and (max-width: 1267px) {
    .main-area {
      width: 800px;
      margin: 0 auto;

      .betting,
      .events-area {
        width: 784px;
        margin: 0 8px auto;
        display: inline-block;
      }

      .betting {
        .playground {
          .mobile-betslip {
            display: none;
          }
        }
      }
    }
    .ticket-area {
      float: right;
      width: 300px;
    }
  }

  @media (min-width: 1268px) and (max-width: 1483px) {
    .main-area {
      width: 968px;
      margin: 0 auto;

      .betting {
        width: 640px;
        float: left;
        margin: 0 8px;

        .playground {
          .mobile-betslip {
            display: none;
          }
        }
      }

      .events-area {
        width: 304px;
        float: left;
      }
    }
  }

  @media (min-width: 1484px) {
    .main-area {
      margin: 0 auto;

      .betting {
        width: 784px;
        float: left;
        margin: 0 8px;

        .playground {
          .mobile-betslip {
            display: none;
          }
        }
      }

      .events-area {
        width: 376px;
        float: left;
      }
    }
    .ticket-area {
      margin-left: 8px;
    }
  }
</style>
